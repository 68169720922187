import request from './request'
import RailsRouter from '../RailsRouter.js.erb'

export const createCycle = (cycle:object) => request({
  method: 'POST',
  url: RailsRouter.adminCyclesPath(),
  data: cycle,
})

export const updateCycle = (cycleId: number, cycle:object) => request({
  method: 'PATCH',
  url: RailsRouter.adminCyclePath(cycleId),
  data: cycle,
})

export const getCycleById = (cycleId: number) => request({
  method: 'GET',
  url: RailsRouter.adminCyclePath(cycleId),
})

export const getCycles = () => request({
  method: 'GET',
  url: RailsRouter.adminCyclesPath({ sort_by_start_date: true }),
})

export const populateParticipants = (cycleId:number) => request({
  method: 'POST',
  url: RailsRouter.populateParticipants(cycleId),
})

export const populateParticipantsJobs = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.populateParticipants(cycleId),
})

export const startCycle = (cycleId:number) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleStartPath(cycleId),
})

export const progressCycleToSpuFeedback = (cycleId:number) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleProgressToSpuFeedbackPath(cycleId),
})

export const progressCycleToManagerFeedback = (cycleId:number) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleProgressToManagerFeedbackPath(cycleId),
})

export const progressCycleToCalibration = (cycleId:number) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleProgressToCalibrationPath(cycleId),
})

export const progressCycleToCommunication = (cycleId:number) => request({
  method: 'PATCH',
  url: RailsRouter.adminCycleProgressToCommunicationPath(cycleId),
})

export const getUserCycles = () => request({
  method: 'GET',
  url: RailsRouter.cyclesPath(),
})

export const getUserInfoForCycle = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.cycleMyInfoPath(cycleId),
})

export const fetchStatistics = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.getAdminStatisticsPath(cycleId),
})

export const fetchPBPDashboard = (cycleId:number) => request({
  method: 'GET',
  url: RailsRouter.getPBPDashboardPath(cycleId),
})

export const getDirectReportCycles = (cycleId:number, email:string) => request({
  method: 'GET',
  url: RailsRouter.managerDirectReportCyclesPath(cycleId, email),
})

export const adminDeleteCycle = (cycleId:number) => request({
  method: 'DELETE',
  url: RailsRouter.deleteCyclePath(cycleId),
})
